<template>
  <div>
    <div class="header" style="padding:10px 0;margin:0;">
      <div class="header_top">
        <img src="../../assets/agent.png" alt="" />
        <span class="center">代理管理</span>
        <img src="../../assets/right.png" alt="" />
        <span class="center">代理的企业</span>
      </div>
      <div style="display: flex;align-items: center;">
        <span class="seach_text">关键字：</span>
        <el-input
          prefix-icon="el-icon-search"
          style="margin-right: 10px; width: 200px"
          v-model="formInline.keywords"
          size="mini"
          placeholder="请输入客户名称"
          @input="loadData('seath')"
        ></el-input>
      </div>
    </div>

    <el-table :data="list" style="width: 100%">
      <el-table-column label="客户名称&统一信用编码" width="250">
        <template slot-scope="scope">
          <div>
            <p>
              {{ scope.row.name }}
            </p>
            <p>{{ scope.row.idCardNumber }}</p>
          </div>
        </template>
      </el-table-column>

      <!-- <el-table-column label="统一信用编码" width="200">
        <template slot-scope="scope">
          {{ scope.row.idCardNumber }}
        </template>
      </el-table-column> -->

      <!-- <el-table-column label="经营范围">
        <template slot-scope="scope">
          {{ scope.row.range }}
        </template>
      </el-table-column> -->

      <!-- <el-table-column label="客户类型">
        <template slot-scope="scope">
          {{ scope.row.type }}
        </template>
      </el-table-column> -->

      <el-table-column label="客户地址" width="250">
        <template slot-scope="scope">
          {{ scope.row.address }}
        </template>
      </el-table-column>

      <!-- <el-table-column label="行业">
        <template slot-scope="scope">
          {{ scope.row.industry }}
        </template>
      </el-table-column> -->

      <el-table-column label="联系人">
        <template slot-scope="scope">
          {{ scope.row.contacts }}
        </template>
      </el-table-column>

      <el-table-column label="联系人电话">
        <template slot-scope="scope">
          {{ scope.row.contactsPhone }}
        </template>
      </el-table-column>

      <!-- <el-table-column label="邮箱" width="180">
        <template slot-scope="scope">
          {{ scope.row.contactsMail }}
        </template>
      </el-table-column> -->

      <el-table-column label="费率">
        <template slot-scope="scope">
          <span v-if="scope.row.rate != '{}'">{{ scope.row.rate }}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.authenticateState === '0'">
            {{ scope.row.authenticateState | enterpriseCertiState }}
          </el-tag>
          <el-tag type="info" v-if="scope.row.authenticateState === '2'">
            {{ scope.row.authenticateState | enterpriseCertiState }}
          </el-tag>
          <el-tag type="warning" v-if="scope.row.authenticateState === '1'">
            {{ scope.row.authenticateState | enterpriseCertiState }}
          </el-tag>
          <el-tag type="danger" v-if="scope.row.authenticateState === '4'">
            {{ scope.row.authenticateState | enterpriseCertiState }}
          </el-tag>
          <el-tag type="success" v-if="scope.row.authenticateState === '3'">
            {{ scope.row.authenticateState | enterpriseCertiState }}
          </el-tag>
        </template>
      </el-table-column>

      <!-- <el-table-column label="跟进分组">
        <template slot-scope="scope">
          {{ scope.row.agentGroupName }}
        </template>
      </el-table-column>
      <el-table-column label="跟进企业" width="250">
        <template slot-scope="scope">
          {{ scope.row.agentEnterpriseName }}
        </template>
      </el-table-column> -->

      <!-- <el-table-column label="跟进个人">
        <template slot-scope="scope">
          {{ scope.row.agentUserName }}
        </template>
      </el-table-column> -->

      <el-table-column label="维护人员" width="200">
        <template slot-scope="scope">
          {{ scope.row.agentPath }}
        </template>
      </el-table-column>
      <el-table-column label="创建时间" width="180">
        <template slot-scope="scope">
          {{ scope.row.createTime }}
        </template>
      </el-table-column>

      <el-table-column label="操作" width="150">
        <template slot-scope="scope">
          <span class="clickColor" @click="setAgent(scope.row)">分配代理</span>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination">
      <span class="clickColor"> 共{{ total }}条</span>
      <span class="clickColor" style="margin:0 10px">
        每页{{ pageSize }}条</span
      >
      <el-pagination
        background
        :current-page.sync="pageNumber"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-size="pageSize"
        prev-text="上一页"
        next-text="下一页"
        layout="prev,pager, next,jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 分配代理弹窗 -->
    <el-dialog :visible.sync="agentVisible" width="520px" :show-close="false">
      <div slot="title" class="left_top">
        <div class="left_icon"><span></span> <span>分配代理</span></div>
        <div class="right_icon"><span></span></div>
      </div>
      <el-form label-position="top">
        <el-form-item label="代理人名称">
          <el-select
            v-model="personnel"
            placeholder="请选择代理人名称"
            style="width:100%"
          >
            <el-option
              v-for="item in personnelList"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button type="primary" @click="submit()">确 定</el-button>
        <el-button @click="closeDialog()">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
//接口：
import { api } from '/src/api/base';
//查询列表接口
export const agentEnterpriseList = api()('agent.enterprise.list.json');
export const agentUserListAccount = api()('agent.user.list.account.json');
export const agentUpdateEnterpriseAgentPath = api()(
  'agent.update.enterprise.agentPath.json',
);

export default {
  components: {
    // ListFilter: () => import("/src/components/filter")
  },
  data() {
    return {
      pageSize: 10,

      pageNumber: 1,
      total: 0,
      formInline: {
        keywords: '',
      },
      list: [],
      agentVisible: false,
      personnel: '',
      personnelList: [],
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    async getTransferList() {
      const { list } = await agentUserListAccount({
        pageNumber: 1,
        pageSize: 1000,
      });
      this.personnelList = list;
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.loadData();
    },
    handleCurrentChange(val) {
      this.pageNumber = val;
      this.loadData();
    },
    putAway() {
      this.filterOpen = false;
      this.pageNumber = 1;
      this.loadData();
    },
    loadData(seath) {
      if (seath) {
        this.pageNumber = 1;
      }
      const params = {
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
        ...this.formInline,
      };
      agentEnterpriseList(params).then((res) => {
        this.list = res.list;
        this.total = res.total;
      });
    },
    goBack() {
      window.history.go(-1);
    },
    handleClick(row) {
      console.log(row.code);
      this.$router.push({
        path: '/system/enterprise/detail',
        query: {
          code: row.code,
        },
      });
    },
    async setAgent(row) {
      this.code = row.code;
      this.agentVisible = true;
      await this.getTransferList();
      this.personnel = this.personnelList.find(
        (it) => it.name === row.agentPath,
      ).code;
    },
    closeDialog() {
      this.personnel = '';
      this.agentVisible = false;
    },
    async submit() {
      if (!this.personnel) {
        return this.$message.error('请先选择代理人员！');
      }
      await agentUpdateEnterpriseAgentPath({
        code: this.code,
        agentUserCode: this.personnel,
      });
      this.$message.success('分配成功！');
      this.closeDialog();
      this.loadData();
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  padding-bottom: 15px;
  border-bottom: 1px solid #ebeef5;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.clickColor {
  color: #409eff;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.left_top {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left_icon {
    display: flex;
    align-items: center;
    span:nth-child(1) {
      padding: 8px 4px;
      background: #4977fc;
      margin-right: 10px;
    }
    span:nth-child(2) {
      font-size: 16px;
      font-weight: 700;
    }
  }
}
</style>
